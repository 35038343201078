<template>
    <div class="clientcabin clientcabin-defi">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 text-subtitle-1">
                    <p class="headline text-center pb-10 my-4">
                        Get Published On 75+ Exclusive Google News Approved
                        Crypto Sites For An Overnight Boost In Rankings,
                        Reputation & Traffic
                    </p>
                    <p>
                        Do you have a Crypto, DeFi or NFT project you’d like
                        more people to know about?
                    </p>
                    <p>
                        Or perhaps you provide a service you’d like to get more
                        traffic and sales for?
                    </p>
                    <p>
                        As the Crypto industry has evolved and gained more
                        mainstream attention, it’s become essential to win
                        attention and trust.
                    </p>
                    <p>
                        To do that, you simply MUST be publishing content AND
                        getting that content seen on trusted, authoritative
                        sites specializing in the crypto industry.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 text-subtitle-1">
                    <v-img
                        class="mb-10"
                        src="@/assets/img/clientcabin/defi/distribution-defi.png"
                    />
                    <p>
                        Producing and publishing content is the key difference
                        between projects that thrive and grow… and projects that
                        fade into obscurity, as investors lose faith, lose trust
                        and sell.
                    </p>
                    <p>
                        Choose to get your project or service the publicity it
                        needs to get seen, get noticed and attract more
                        attention, trust and investment or sales.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="py-6 py-md-10 text-subtitle-1"
                    >
                        <p class="subheadline text-center pb-6 mb-4">
                            So How Do You Get Publicity In Such A Busy Industry?
                        </p>
                        <p>This is our expertise.</p>
                        <p>
                            The fastest way is to have interesting newsworthy
                            content written about your project and published on
                            industry focused news sites.
                        </p>
                        <p class="font-weight-bold">Sites Like:</p>
                        <v-img
                            class="my-10"
                            src="@/assets/img/clientcabin/defi/distribution-network-loci.png"
                        />
                        <p>
                            Each of the sites in our network are independently
                            owned, trusted authorities, approved in Google News.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p class="subheadline text-center pb-6 my-4">
                        Google Trusts These Sites So Much Their Content Gets
                        Preferred Treatment
                    </p>
                    <p>
                        Google will give content published on these sites more
                        exposure than less trusted websites.
                    </p>
                    <p>
                        This means with our service, you can start getting
                        greater publicity in just a few hours.
                    </p>
                    <p>
                        We’ll identify the highest impact opportunities for your
                        project to make sure you get seen, get heard, win trust
                        and make sure your project gets the recognition it
                        deserves.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="py-6 py-md-10 text-subtitle-1"
                    >
                        <p class="subheadline text-center pb-6 my-4">
                            How Does It Work?
                        </p>
                        <p>Simple!</p>
                        <p>
                            Once you select your package below (based on how
                            many content pieces, or topics, you’d like to have
                            published)…
                        </p>
                        <p>
                            We’ll ask a few questions about your project or
                            service and get to work.
                        </p>
                        <p>
                            Within a few days, the content will be ready, you’ll
                            be able to review and approve; and we’ll get the
                            piece published immediately after that.
                        </p>
                        <p>
                            Within 36 hours that content piece will be live on
                            75+ Exclusive Google News Approved crypto sites.
                        </p>
                        <p>
                            We’ll repeat the process for as long as you wish to
                            continue.
                        </p>
                        <p class="px-6 pt-6">
                            <b>Note:</b>
                            The most consistently you publish content about your
                            project or service, the more significant the results
                            will be. We’ve created several packages to fit your
                            scale and budget; with heavier discounts for bigger
                            campaigns.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <p class="text-h5 text-center">Select Your Option Below</p>
                    <v-container class="clientcabin-pricing">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card elevation="10" class="rounded-lg">
                                    <v-img
                                        class="black--text align-center grey lighten-4"
                                        min-height="160px"
                                    >
                                        <div
                                            class="text-center mt-n5 primary white--text text-button"
                                        >
                                            Most Popular
                                        </div>
                                        <div
                                            class="text-center option-title pa-4"
                                        >
                                            Option 1
                                            <span class="option-plan">
                                                Plan 1
                                            </span>
                                            <br />
                                            <b>Single Purchase @</b>
                                        </div>
                                    </v-img>
                                    <div class="price text-center">
                                        {{ reseller_client_defi_credit_price }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn
                                            x-large
                                            color="primary white--text"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="px-10 py-8 text-h6 rounded-lg"
                                            @click="onOrder('single')"
                                        >
                                            Add to cart
                                        </v-btn>
                                    </v-card-text>
                                    <v-card-text class="pt-0 text-center">
                                        <v-icon class="mr-1">
                                            $vuetify.icons.secure
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.mastercard
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.amex
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.visa
                                        </v-icon>
                                        <v-icon large>
                                            $vuetify.icons.paypal
                                        </v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card elevation="10" class="rounded-lg">
                                    <v-img
                                        class="black--text align-center grey lighten-4"
                                        min-height="160px"
                                    >
                                        <div
                                            class="text-center option-title pa-4 mt-4"
                                        >
                                            Option 2
                                            <span class="option-plan">
                                                Plan 2
                                            </span>
                                            <br />
                                            <b>Monthly Purchase @</b>
                                        </div>
                                    </v-img>
                                    <div class="price text-center">
                                        {{
                                            reseller_client_recurring_defi_credit_price
                                        }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn
                                            x-large
                                            color="primary white--text"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="px-10 py-8 text-h6 rounded-lg"
                                            @click="onOrder('recurring')"
                                        >
                                            Add to cart
                                        </v-btn>
                                    </v-card-text>
                                    <v-card-text class="pt-0 text-center">
                                        <v-icon class="mr-1">
                                            $vuetify.icons.secure
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.mastercard
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.amex
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.visa
                                        </v-icon>
                                        <v-icon large>
                                            $vuetify.icons.paypal
                                        </v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class DeFi extends Vue {
    endpoint = '/reseller_sales_pages/defi';

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get reseller_client_defi_credit_price() {
        return this.format(this.options?.reseller_client_defi_credit_price);
    }

    get reseller_client_recurring_defi_credit_price() {
        return `${this.format(
            this.options?.reseller_client_recurring_defi_credit_price
        )}/mo`;
    }

    format(price?: number) {
        if (price) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.options?.currency || 'USD',
                maximumFractionDigits: 0
            }).format(price);
        }

        return price;
    }

    onOrder(product: 'single' | 'recurring') {
        this.$http
            .post(this.endpoint, {
                [product]: 1
            })
            .then(({ data }) => {
                if (data.data.success) {
                    this.onSuccess(data.data);
                }
            });
    }

    onSuccess({ redirect }: { redirect?: string }) {
        if (redirect) {
            // payment page
            window.location.href = redirect;
        }
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-defi::v-deep {
    color: $black;
    background-color: $white;

    .option-title {
        font-size: 1.25rem;
        line-height: 1.8em;
        text-transform: uppercase;

        .option-plan {
            border: 2px solid grey;
            padding: 4px 8px;
            margin-left: 8px;
            font-weight: 600;
        }
    }

    .price {
        font-weight: 700;
        font-size: 67px;
        letter-spacing: -1px;
        text-align: center;
        padding: 25px 0;
        color: $black;
    }
}
</style>
